import React from 'react'
import BEMHelper from 'react-bem-helper'
import { Swipeable } from 'react-swipeable'
import './Slider.scss'
const classes = new BEMHelper({
  name: 'Slider',
})

const Slider = ({ children, open, onChangeState = () => {} }) => {
  return (
    <Swipeable
      onSwipedRight={() => onChangeState(true)}
      onSwipedLeft={() => onChangeState(false)}
    >
      <div
        {...classes(null, { open: open })}
      >
        <div {...classes('LabelControl')}>
          <div
            {...classes(
              'LabelControl__Label',
              null,
              'has-text-weight-bold is-size-7 is-capitalized'
            )}
          >
            оглавление
          </div>
        </div>
        <div {...classes('Content')}>{children}</div>
      </div>
    </Swipeable>
  )
}

export default Slider
