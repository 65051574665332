import React from 'react'
import rehypeReact from 'rehype-react'
import Blockquote from '../Blockquote/Blockquote'
import ImageInPageProxy from './ComponentProxy/ImageInPageProxy'
import LinkProxy from './ComponentProxy/LinkProxy'
import EmailLink from '../EmailLink/EmailLink'
import TableProxy from './ComponentProxy/TableProxy'
import TheadProxy from './ComponentProxy/TheadProxy'
import { titleProxyBuilder } from './ComponentProxy/TitleProxy'

const components = {
  img: ImageInPageProxy,
  a: LinkProxy,
  h1: titleProxyBuilder(1),
  h2: titleProxyBuilder(2),
  h3: titleProxyBuilder(3),
  h4: titleProxyBuilder(4),
  h5: titleProxyBuilder(4),
  h6: titleProxyBuilder(5),
  h7: titleProxyBuilder(6),
  table: TableProxy,
  thead: TheadProxy,
  'wal-blockquote': Blockquote,
  'wal-email-link': EmailLink,
}

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components,
}).Compiler

const HTMLContent = ({ html, className }) => {
  const _html = typeof html === 'string' ? JSON.parse(html) : html
  return (
    <div className="content">
      <div className={className}>{renderAst(_html)}</div>
    </div>
  )
}

export default HTMLContent
