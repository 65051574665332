import { Link } from 'gatsby'
import React from 'react'

const LinkProxy = ({ href, children }) => {
  if (href[0] === '/') {
    return <Link
      to={href}
    >
      {children}
    </Link>
  }

  return <a href={href}>{children}</a>
}

export default LinkProxy
