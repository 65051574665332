import React from "react"
import './Title.scss'

function Title({children, level, className, id}) {
  const Tag = level ? `h${level + 2}` : 'h2'
  const levelClass = level ? `is-${level + 2}` : 'is-2'

  return <Tag id={id} className={`HeaderTitle title ${levelClass} is-family-secondary ${className}`}>{children}</Tag>
}

export default Title;