import React from 'react'
import BEMHelper from 'react-bem-helper'
import './IconButon.scss'

const classes = new BEMHelper({
  name: 'IconButton',
})

const IconButton = ({Icon, url, modifier, onClick = () => {}}) => {
  return (
    <span {...classes(null, modifier)}>
      <a href={url} {...classes('Button', null, 'button has-text-white')} onClick={onClick}>
        <span {...classes('Icon', null,'icon is-medium')}>
          <Icon
            size="1.5rem"
          />
        </span>
      </a>
    </span>

  )
}

export default IconButton
