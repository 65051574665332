import React, { Component } from 'react'
import BEMHelper from 'react-bem-helper'
import { FaSearchPlus } from 'react-icons/fa'
import IconRound from '../../Icon/IconRound/IconRound'
import Modal from '../../Modal/Modal'
import Blender from '../../Blender/Blender'
import ImageContainer from '../ImageContainer/ImageContainer'

import './ImagePreview.scss'
import Image from "../Image"

const classes = new BEMHelper({
  name: 'ImagePreview',
})

class ImagePreview extends Component {
  state = {
    modalActive: false,
  }

  openModal = () => {
    this.setState({
      modalActive: true
    })
  }

  closeModal = () => {
    this.setState({
      modalActive: false
    })
  }

  render() {
    const { modalActive } = this.state

    return (
      <>
        <span {...classes()}>
          <ImageContainer {...this.props} />
          <Blender>
            <span {...classes('Magnifier')} onClick={this.openModal}>
              <IconRound
                modifier="sm"
                icon={<FaSearchPlus />}
              />
            </span>
          </Blender>
        </span>
        <Modal modifier="foto"
               modalActive={modalActive}
               onClose={this.closeModal}>
          <Image pure={true} {...classes('BigImage')} {...this.props} />
        </Modal>
      </>
    )
  }
}

export default ImagePreview
