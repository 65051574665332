import React from "react"
import HTMLContent from "../../wal-common/src/components/HTMLContent/HTMLContent"
import ImageInPage from "../../wal-common/src/components/Image/ImageInPage/ImageInPage"

const PagesRepresentator = ({ html, slug }) => {
  const regExpRes = slug.match(/(\d\d\d)-(\d\d\d)/)

  let startPage = 0;
  let endPage = -1;

  if (regExpRes) {
    startPage = regExpRes[1];
    endPage = regExpRes[2]
  }

  const pages = []

  for (let i = startPage; i <= endPage; i++) {
    pages.unshift(`page${i}.jpg`)
  }

  return (
    <>
      <div className="is-clearfix">
        {pages.map(p => (
          <ImageInPage key={p} file={p} width="is-one-third" />
        ))}
      </div>
      <HTMLContent html={html} />
    </>
  )
}

export default PagesRepresentator
