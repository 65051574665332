import React from 'react'
import './IconRound.scss'
import BEMHelper from 'react-bem-helper'

const classes = new BEMHelper({
  name: 'IconRound',
})

function IconRound({ icon, modifier }) {
  return (
    <div
      {...classes(
        null,
        modifier,
        'has-text-white has-shadow'
      )}
    >
      {icon}
    </div>
  )
}

export default IconRound
