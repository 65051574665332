import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { isInCms } from '../../util/util'

const Image = ({ file, alt, title, thumb, pure, className, ...props }) => {
  if (isInCms()) {

    const _file = file.slice(0, 7) === '/media/' ? file : `/media/${file}`

    return (
      <img
        style={{
          display: 'block',
          width: '100%'
        }}
        src={_file}
        alt={alt || title || file}
        title={title || alt || file}
      />
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const ind = data.images.edges.findIndex(n => {
          return n.node.relativePath === file
        })

        const image = data.images.edges[ind]

        if (!image) {
          return null
        }

        const _alt = alt || title || file;
        const _title = title || alt || file;

        if (thumb) {
          return (
            <Img
              tag="span"
              alt={_alt}
              title={_title}
              fluid={{
                ...data.images.edges[ind].node.childImageSharp.fluid,
                aspectRatio: 3/2
              }}
              // fixed={data.thumbs3by2.edges[ind].node.childImageSharp.fixed}
              {...props}
            />
          )
        } else if (pure) {
          return <img alt={_alt} title={_title} className={className} src={data.images.edges[ind].node.childImageSharp.fluid.src}/>
        }

        return (
          <Img
            tag="span"
            alt={_alt}
            title={_title}
            fluid={data.images.edges[ind].node.childImageSharp.fluid}
            {...props}
          />
        )
      }}
    />
  )
}

export default Image
