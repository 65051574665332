import React, { Component } from 'react'
import BEMHelper from 'react-bem-helper'

import './Blender.scss'

const classes = new BEMHelper({
  name: 'Blender',
})

class Blender extends Component {
  refElOverlay = React.createRef()
  refEl = React.createRef()

  state = {
    visible: false,
  }

  componentDidMount() {
    const { visible } = this.props;
    this.setState({
      visible
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.visible !== prevProps.visible) {
      this.setState({
        visible: this.props.visible
      })
    }
  }

  mouseOverHandler = visible => (e) => {
    if (visible) {
      if (!this.state.visible) {
        const {width, height, left, top} = this.refEl.current.getBoundingClientRect();
        const r = {
          left: left,
          top: top,
          width,
          height
        }

        const p = {
          x: e.clientX - r.left - width / 2,
          y: e.clientY - r.top - height / 2
        }

        const k = height / width
        const c1 = k  * p.x;
        const c2 = -k  * p.x;

        let side = null;

        if (p.y > c1) {
          side = p.y > c2 ? 'b' : 'l'
        } else {
          side = p.y > c2 ? 'r' : 't'
        }

        const node = this.refElOverlay.current;
        node.style.transition = 'none';

        switch (side) {
          case 't':
            node.style.top = '-100%';
            node.style.bottom = '100%';
            break
          case 'r':
            node.style.left = '100%';
            node.style.right = '-100%';
            break;
          case 'b':
            node.style.top = '100%';
            node.style.bottom = '-100%';
            break
          case 'l':
            node.style.left = '-100%';
            node.style.right = '100%';
            break;
          default:
            break
        }

        setTimeout(() => {
          node.style.transition = null;
          node.style.left = null;
          node.style.right = null;
          node.style.top = null;
          node.style.bottom = null;
          this.setState({
            visible: true
          })
        }, 10)
      }
    } else {
      this.setState({
        visible,
      })
    }
  }

  render() {
    const { children } = this.props
    const { visible } = this.state

    return (
      <span
        ref={this.refEl}
        onMouseOver={this.mouseOverHandler(true)}
        onMouseLeave={this.mouseOverHandler(false)}
        {...classes(null, null, 'is-overlay')}
      >
        <span {...classes('Margin')}>
          <span
            ref={this.refElOverlay}
            {...classes('Overlay', visible ? 'visible' : null, 'is-overlay')}
          >
            {children}
          </span>
        </span>
      </span>
    )
  }
}

export default Blender
