import React from 'react'
import BEMHelper from 'react-bem-helper/index'
import './Blockquote.scss'

const classes = new BEMHelper({
  name: 'Blockquote',
});

const Blockquote = ({caption, children}) => {
  return (
    <figure {...classes(null, null, 'has-text-left')}>
      <figcaption className="has-text-primary has-text-weight-semibold">{caption}</figcaption>

      <blockquote {...classes('Quote')}>
        {children}
      </blockquote>
    </figure>
  )
}

export default Blockquote
