import React from 'react'

const TableProxy = ({ children }) => {
  return (
    <thead className="has-background-primary">
      {children}
    </thead>
  )
}

export default TableProxy
