import React from "react"

const EmailLink = ({children}) => {
  return (
    <a href={`mailto:${children}`}>
      {children}
    </a>
  )
}

export default EmailLink
