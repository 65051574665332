import React from 'react'

const TableProxy = ({ children }) => {
  return (
    <table className="table is-hoverable is-fullwidth">
      {children}
    </table>
  )
}

export default TableProxy
