import React from 'react';
import Back2Top from 'react-scroll-up';
import { FaArrowUp } from 'react-icons/fa'
import IconRound from '../Icon/IconRound/IconRound'
import './BackTopTop.scss'

const BackToTop = () => {
  return (
    <div className="BackToTop">
      <Back2Top
                showUnder={200}
                style={{
                  right: 50,
                }}>
        <IconRound
          modifier="lg"
          icon={<FaArrowUp/>}
        />
      </Back2Top>
    </div>
  );
};

export default BackToTop;
