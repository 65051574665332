import React from 'react'
import BEMHelper from 'react-bem-helper'
import './ImageInPage.scss'
import ImagePreview from '../ImagePreview/ImagePreview'

const classes = new BEMHelper({
  name: 'ImageInPage',
});

const ImageInPage = ({align, width, file, ...props}) => {
  const _align = align || 'right';

  if (!file) {
    return null;
  }

  return (
    <span {...classes(null, [_align, width])}>
      <ImagePreview file={file} {...props} />
    </span>
  )
}

export default ImageInPage
