import React from 'react'
import { FaEnvelope, FaFacebook, FaVk } from 'react-icons/fa'
import IconButton from '../../wal-common/src/components/Icon/IconButton/IconButton'

function SocialLinks() {
  return (
    <div className="m-b-md m-t-md">
      <span>
        <IconButton
          modifier="rotatedOnHover"
          url="https://www.facebook.com/permalink.php?story_fbid=1293640440810361&id=100004932525249"
          Icon={FaFacebook}
        />
      </span>
      <span className="m-l-md">
        <IconButton
          modifier="rotatedOnHover"
          url="mailto:chirkov@chirkov.de"
          Icon={FaEnvelope}
        />
      </span>
    </div>
  )
}

export default SocialLinks;