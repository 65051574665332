import React from "react"
import Slider from "../../wal-common/src/components/Slider/Slider"
import ToC from "../../wal-common/src/components/ToC/ToC"

class LocalSlider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  render() {
    const { open } = this.state
    const { headings } = this.props

    return (
      <Slider
        open={open}
        onChangeState={open => {
          this.setState({
            open,
          })
        }}
      >
        <div className="m-md">
          <ToC
            headings={headings}
            deltaDepth={-1}
            endDepth={2}
            onClick={() => {
              this.setState({
                open: false,
              })
            }}
          />
        </div>
      </Slider>
    )
  }
}

export default LocalSlider
