const remark = require('remark')
const remarkHTML = require('remark-html')
const unified = require('unified')
const markdown = require('remark-parse')
const remark2rehype = require('remark-rehype')
var raw = require('rehype-raw')

module.exports = {
  isInCms: () => typeof window !== 'undefined' && window.___CMS,

  md2html: md => remark()
    .use(remarkHTML)
    .processSync(md)
    .toString(),

  md2htmlAst: md => unified()
    .use(markdown)
    .use(remark2rehype)
    .use(raw)
    .use(function stringify() {
      this.Compiler = compiler

      function compiler(tree) {
        return JSON.stringify(tree)
      }
    })
    .processSync(md).contents
}
