import React from 'react'
import { isInCms } from '../../../util/util'
import ImageInPage from '../../Image/ImageInPage/ImageInPage'

const ImageInPageProxy = ({ src, ...rest }) => {
  return (
    <ImageInPage
      {...rest}
      align='left'
      file={isInCms() ? src : src.replace('/media/', '')}
    />
  )
}

export default ImageInPageProxy
