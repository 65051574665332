import React from 'react'
import { scroller } from 'react-scroll'

const Leaf = ({ name, childs, onClick }) => (
  <>
    {name && <a onClick={() => {
      scroller.scrollTo(encodeURI(name), {
        duration: 500,
        smooth: true,
      })
      if(onClick) {
        onClick()
      }
    }}>{name}</a>}
    {childs && childs.length && <Branches childs={childs} onClick={onClick} />}
  </>
)

const Branches = ({ childs, onClick }) => (
  <ul className="menu-list">
    {childs.map(({ name, childs: kids }) => (
      <li key={name}>
        <Leaf name={name} childs={kids} onClick={onClick} />
      </li>
    ))}
  </ul>
)

const ToC = ({ headings, endDepth = 10, deltaDepth = 0, onClick }) => {
  let currentLevel = 0

  const curLeafs = {
    0: {
      childs: [],
    },
  }

  const getLeaf = level => {
    if (typeof curLeafs[level] === 'undefined') {
      curLeafs[level] = {}
      if (level > -1) {
        const parentChilds = getLeaf(level - 1)
        if (typeof parentChilds.childs === 'undefined') {
          parentChilds.childs = []
        }

        parentChilds.childs.push(curLeafs[level])
      }
    }

    return curLeafs[level]
  }

  headings.forEach(({ depth: _depth, value }) => {
    const depth = _depth + deltaDepth;

    if (depth > endDepth || depth < 1) {
      return
    }

    if (depth < currentLevel) {
      for (let i = currentLevel; i > depth; i--) {
        delete curLeafs[i]
      }
    }

    delete curLeafs[depth]

    const leaf = getLeaf(depth)
    leaf.name = value
    currentLevel = depth
  })

  return (
    <aside className="menu">
      <Branches childs={curLeafs[0].childs} onClick={onClick} />
    </aside>
  )
}

export default ToC
