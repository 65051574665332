import React from 'react'
import Image from '../Image'
import './ImageContainer.scss'

export default function ImageContainer({title, ...props}) {
  return (
    <span className="ImageContainer box is-marginless">
      <Image title={title} {...props} />
    </span>
  )
}
