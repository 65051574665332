import React from "react"
import Helmet from "react-helmet"
import { StaticQuery } from "gatsby"

const Layout = ({ children }) => (
  <>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: { title, description },
        },
      }) => (
        <>
          <Helmet
            htmlAttributes={{
              lang: 'ru',
            }}
            title={title}
            meta={[
              {
                name: `description`,
                content: description,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: description,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: 'https://ww2-in-der-schule.chirkov.de/media/page146-2.jpg'
//                content: `https://ww2-in-der-schule.chirkov.de/media/page143-5.jpg`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: description,
              },
            ]}
          />
        </>
      )}
    />

    {children}
  </>
)

export default Layout
