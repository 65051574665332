import React from "react"
import "./styles.scss"
import Layout from "../components/layout"
import { StaticQuery } from "gatsby"
import PagesRepresentator from "../components/PagesRepresentator/PagesRepresentator"
import BackToTop from "../wal-common/src/components/BackToTop/BackToTop"
import LocalSlider from "../components/LocalSlider/LocalSlider"
import ToC from "../wal-common/src/components/ToC/ToC"
import SocialLinks from "../components/SocialLinks/SocialLinks"

const Index = () => {
  return (
    <Layout>
      <StaticQuery
        query={graphql`
          query NewsQuery {
            allMarkdownRemark(
              sort: { order: ASC, fields: [fields___slug] }
              filter: { fields: { slug: { glob: "/text/*/" } } }
            ) {
              edges {
                node {
                  headings {
                    value
                    depth
                  }
                  htmlAst
                  fields {
                    slug
                  }
                }
              }
            }
          }
        `}
        render={({ allMarkdownRemark: { edges } }) => {
          const allHeadings = edges.reduce(
            (acc, { node: { headings } }) => [...acc, ...headings],
            []
          )

          return (
            <>
              <div className="container">
                <div className="columns">

                  <div className="column is-three-quarters-tablet">
                    <div className="section">
                      {edges.map(({ node: { htmlAst, fields: { slug } } }, ind) => (
                        <div key={slug} className="m-b-xl is-clearfix">
                          <PagesRepresentator key={slug} html={htmlAst} slug={slug} />
                          {ind === 0 && <>
                            <div className="has-text-right">
                              Спасибо,
                              <br />Александр Чирков
                              <SocialLinks />
                            </div>
                          </>}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="column is-hidden-mobile is-one-quarter">
                    <div className="section" style={{
                      position: 'sticky',
                      top: 0
                    }}>
                      <ToC
                        headings={allHeadings}
                        deltaDepth={-1}
                        endDepth={2}
                        onClick={() => {
                        }} />
                    </div>
                  </div>

                </div>
              </div>

              <BackToTop />

              <div className="is-hidden-tablet">
                <LocalSlider headings={allHeadings} />
              </div>

            </>
          )
        }}
      />

    </Layout>
  )
}

export default Index
